import logoLight from "@/images/st-logo.png";
import logoDark from "@/images/st-logo.png";
import flagLight from "@/images/germany.png";
import flagDark from "@/images/germany.png";
export const LogoImage = {
  light: logoLight,
  dark: logoDark,
};
export const flagImage = {
  light: flagLight,
  dark: flagDark,
};

export const NavLinks = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Services",
    url: "/service",
    subItems: [
      {
        name: "Services",
        url: "/service",
      },
      {
        name: "Website Development",
        url: "/website-development",
      },
      {
        name: "Graphics Designing",
        url: "/website-graphics",
      },
      {
        name: "Digital Marketing",
        url: "/digital-marketing",
      },
      {
        name: "Cyber Security",
        url: "/cyber-security",
      },
      {
        name: "App Development",
        url: "/app-development",
      },
    ],
  },
  // {
  //   name: "Portfolio",
  //   url: "/portfolio",
  //   subItems: [
  //     {
  //       name: "Portfolio 01",
  //       url: "/portfolio",
  //     },
  //     {
  //       name: "Portfolio Details",
  //       url: "/portfolio-details",
  //     },
  //   ],
  // },
  // {
  //   name: "Blog",
  //   url: "/blog",
  //   subItems: [
  //     {
  //       name: "Blog 01",
  //       url: "/blog",
  //     },
  //     {
  //       name: "Blog 02",
  //       url: "/blog-2",
  //     },
  //     {
  //       name: "Blog 03",
  //       url: "/blog-3",
  //     },
  //     {
  //       name: "Blog Details",
  //       url: "/blog-single",
  //     },
  //   ],
  // },
  {
    name: "Contact",
    url: "/contact",
  },
];

const SliderOneData = {
  subTitle: "WELCOME TO SOLTRIKS",
  title: "Development \n  & \n CyberSecurity",
  button: {
    label: "DISCOVER MORE",
    url: "/about",
  },
  background: "black",
};

export const ContactInfosBlock = {
  subTitle: "our offices",
  title: "offices near you",
  description:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const ContactInfosList = [
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@soltriks.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@soltriks.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@soltriks.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@soltriks.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Contact with us",
  title: "write us a message",
  description:
    "We are committed to providing our customers with exceptional service while \n      offering our employees the best training. ",
};

import blogImage1 from "@/images/blog/1.jpg";
import blogImage2 from "@/images/blog/2.jpg";
import blogImage3 from "@/images/blog/3.jpg";
import blogImage4 from "@/images/blog/4.jpg";
import blogImage5 from "@/images/blog/5.jpg";
import blogImage6 from "@/images/blog/6.jpg";

export const BlogData = [
  {
    title: "basic rules of running web agency business",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

import blogImageTwo1 from "@/images/blog/14.jpg";
import blogImageTwo2 from "@/images/blog/15.jpg";

export const BlogTwoData = [
  {
    title:
      "Dynamically procrastinate unique vortals with global best practices.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title:
      "Holisticly conceptualize backend scenarios via accurate technologies.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

import blogS1 from "@/images/blog/11.jpg";
import blogS2 from "@/images/blog/12.jpg";
import blogS3 from "@/images/blog/13.jpg";

export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];

import commentImage1 from "@/images/blog/9.jpg";
import commentImage2 from "@/images/blog/10.jpg";

export const BlogComments = [
  {
    image: commentImage1,
    name: "David Martin",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Jessica Brown",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];

import portfolio01 from "@/images/portfolio/1.jpg";
import portfolio02 from "@/images/portfolio/2.jpg";
import portfolio03 from "@/images/portfolio/3.jpg";
import portfolio04 from "@/images/portfolio/4.jpg";
import portfolio05 from "@/images/portfolio/5.jpg";
import portfolio06 from "@/images/portfolio/6.jpg";

export const PortfolioData = [
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio01,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio02,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio03,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio04,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio05,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio06,
    url: "/portfolio-details",
  },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

import portfolioD01 from "@/images/portfolio/7.jpg";
import portfolioD02 from "@/images/portfolio/8.jpg";

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: "Design Styles",
  text:
    " Tincidunt elit magnis nulla facilisis sagittis sapien nunc amet ultrices dolores sit ipsum velit purus aliquet massa fringilla leo orci. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci dolors sit amet elit amet. ",
  client: "Jessica Brown",
  categories: [
    {
      name: "Graphic,",
      url: "#",
    },
    {
      name: "Illustrations,",
      url: "#",
    },
  ],
  date: "8 Nov, 2018",
  socials: [
    {
      name: "Facebook",
      url: "#",
    },
    {
      name: "Twitter",
      url: "#",
    },
    {
      name: "Linkedin",
      url: "#",
    },
  ],
};

export const ServicePostData = [
  {
    title: "Modren Design",
    text: "Crafting captivating web experiences with sleek, intuitive designs.",
    iconName: "mei-settings",
    url: "/service-details",
  },
  {
    title: "Digital products",
    text:
      "Transforming ideas into cutting-edge digital products that captivate audiences.",
    iconName: "mei-transfer",
    url: "/service-details",
  },
  {
    title: "Marketing Strategy",
    text:
      "Empower your brand with data-driven marketing strategies tailored for success.",
    iconName: "mei-pie-chart",
    url: "/service-details",
  },
];

// export const ServiceHomeTwoData = {
//   subTitle: "welcome to smart meipaly web agency",
//   title: "We design digital products that \n help grow businesses.",
//   text:
//     "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
// };

export const ServiceHomeThreeData = {
  subTitle: "Services we provide",
  title: "Our Services",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "Mastering Solutions, \n Employing Clever Tricks ",
    // subTitle: "SOLTRIKS",
    text: "We offers comprehensive solutions in development and cybersecurity.",
  },
  posts: [
    {
      title: "Website Development",
      iconName: "mei-web-design",
      url: "/website-development",
    },
    {
      title: "Graphic Designing",
      iconName: "mei-computer-graphic",
      url: "/website-graphics",
    },
    {
      title: "Digital Marketing",
      iconName: "mei-development-1",
      url: "/digital-marketing",
    },
    {
      title: "Cyber - Security",
      iconName: "mei-development",
      url: "/cyber-security",
    },
    {
      title: "App Development",
      iconName: "mei-app-development",
      url: "/app-development",
    },
  ],
};

import serviceOne01 from "@/images/home_1/code.jpg";
import serviceOne02 from "@/images/home_1/digital.jpg";
import serviceOne03 from "@/images/home_1/cyber.jpg";

export const ServicePostThreeData = {
  sectionContent: {
    title: "LET'S INNOVATE TOGETHER",
    subTitle: "what we do",
    text:
      "  We're dedicated to delivering exceptional service to our clients while \n providing our team with top-notch training opportunities",
  },
  posts: [
    {
      title: "web development",
      image: serviceOne01,
      url: "/website-development",
    },
    {
      title: "digital marketing",
      image: serviceOne02,
      url: "/digital-marketing",
    },
    {
      title: "Cyber Security",
      image: serviceOne03,
      url: "/cyber-security",
    },
  ],
};

export const FunfactData = [
  {
    title: "Projects Completed",
    countNumber: 15,
  },
  {
    title: "Active Clients",
    countNumber: 12,
  },
  {
    title: "Cups of Coffee",
    countNumber: 70,
  },
  {
    title: "Happy Clients",
    countNumber: 39,
  },
];

import trustClient01 from "@/images/home_1/trustt.jpg";

export const TrustClientData = {
  image: trustClient01,
  title: "We are trusted by more than 39 clients",
  text:
    "At SOLTRIKS, we take pride in delivering exceptional development services that drive success and innovation. Our team is dedicated to providing customized solutions that meet the unique needs of each client. With a commitment to excellence and a track record of success, we ensure your projects are executed seamlessly and efficiently.",
  url: "/about",
};

import ClientCarousel01 from "@/images/client/1.png";
import ClientCarousel02 from "@/images/client/2.png";
import ClientCarousel03 from "@/images/client/3.png";
import ClientCarousel04 from "@/images/client/4.png";
import ClientCarousel05 from "@/images/client/5.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "they trust us",
    subTitle: "our clients",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    // {
    //   url: "#",
    //   image: ClientCarousel03,
    // },
    // {
    //   url: "#",
    //   image: ClientCarousel04,
    // },
    // {
    //   url: "#",
    //   image: ClientCarousel05,
    // },
  ],
};

import aboutOne01 from "@/images/about/about1.jpg";
import aboutOne02 from "@/images/about/about22.jpg";

export const AboutOneData = {
  sectionContent: {
    title: "build better website alot quicker with SolTriks company",
    subTitle: "get to know us",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Company Started",
    number: 2024,
  },
};

import team01 from "@/images/team/1.jpg";
import team02 from "@/images/team/2.jpg";
import team03 from "@/images/team/3.jpg";
import team04 from "@/images/team/4.jpg";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title: "expert people",
    subTitle: "meet the team",
    text:
      " Our team is comprised of passionate individuals, each bringing unique talents and skills to the table. \n From visionary developers to vigilant cybersecurity experts, every member of our team plays a crucial role in delivering exceptional solutions to our clients.",
  },
  posts: [
    {
      image: team01,
      name: "Dede Hyseni",
      designation: "General Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    // {
    //   image: team02,
    //   name: "Fidan Ymeri",
    //   designation: "Senior Developer",
    //   url: "",
    //   socials: [
    //     {
    //       name: "Facebook",
    //       url: "#",
    //     },
    //     {
    //       name: "Twitter",
    //       url: "#",
    //     },
    //     {
    //       name: "Linkedin",
    //       url: "#",
    //     },
    //   ],
    // },
    {
      image: team03,
      name: "Fortesa Krasniqi",
      designation: "Fullstack Developer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Granit Krasniqi",
      designation: "Full Stack Developer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Armend Binaku",
      designation: "Cyper Security",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};

import video01 from "@/images/about/3.jpg";

export const VideoOneData = {
  sectionContent: {
    title: "Make amazing websites without touching cod",
    subTitle: "the only design you need",
    text:
      "Tincidunt elit magnis nulla facilisis sagittis maecenas. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci. Lorem ipsum dolors sit amet elit magnis amet ultrices purus aliquet.",
  },
  video: {
    image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video",
  },
};

import testimonial01 from "@/images/home_1/client.jpg";
import testimonial02 from "@/images/home_1/client.jpg";
import testimonial03 from "@/images/home_1/client.jpg";

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "our customers",
    title: "happy customers",
  },
  posts: [
    {
      name: "Cecilia Colon",
      designation: "Director",
      image: testimonial01,
      content:
        "At SolTriks, we believe in transforming visions into digital realities. Our commitment to excellence and our passion for innovation have helped us build a community of satisfied clients who trust us with their web development needs. But don't just take our word for it—see what our happy customers have to say!",
      date: "25 march, 2024",
    },
    {
      name: "Chase Hanson",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "Working with SolTriks was a game-changer for our business. Their creative approach and technical expertise helped us launch a website that not only looks stunning but also performs flawlessly. Our online presence has never been stronger!",
      date: "14 jan, 2024",
    },
    {
      name: "Mittie Flores",
      designation: "Manager",
      image: testimonial03,
      content:
        "Choosing SolTriks for our web development project was the best decision we made. Their team was always available to address our questions and concerns, ensuring a smooth and stress-free experience. We couldn't be happier with the results!",
      date: "15 april, 2024",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "Thanks to SolTriks, our website stands out in a competitive market. They implemented the latest technologies and best practices, giving us a competitive edge. Their expertise is unmatched!",
      date: "25 feb, 2024",
    },
    {
      name: "Anne Stone",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "The team at SolTriks delivered our project on time and within budget, without sacrificing quality. Their streamlined process and professional attitude made the entire experience smooth and hassle-free. We're thrilled with our new website!",
      date: "6 april, 2024",
    },
    {
      name: "Hunter Brewer",
      designation: "Manager",
      image: testimonial03,
      content:
        "SolTriks took the time to understand our brand and our goals. They crafted a website that truly reflects our identity and engages our audience. Their personal touch and attention to detail made all the difference",
      date: "10 may, 2024",
    },
  ],
};

import featureTab01 from "@/images/home_1/c22.jpg";
import featureTab02 from "@/images/home_1/c11.png";
import featureTab03 from "@/images/home_1/c33.png";

export const FeatureTabData = {
  sectionContent: {
    title: "why choose us",
    subTitle: "our benefits",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
  posts: [
    {
      title: "Latest technology",
      content:
        "Web development in 2024 leverages cutting-edge technologies for improved performance and user experience. Progressive Web Apps (PWAs) offer a native app-like experience with offline functionality and faster load times. Single Page Applications (SPAs), built with frameworks like React and Vue.js, provide seamless user interactions by dynamically loading content. Server-Side Rendering (SSR), using frameworks like Next.js, enhances SEO and load times by combining traditional multi-page benefits with modern SPA. ",
      image: featureTab01,
      lists: [
        {
          item: "Self-contained, state-of-the-art technology stack",
        },
        {
          item: "Scalability",
        },

        {
          item: "Integration Capabilities",
        },
        {
          item: "Robust Security Measures",
        },
      ],
    },
    {
      title: "Awesome Support",
      content:
        "At SolTriks, we pride ourselves on providing exceptional support to our clients. We understand that technical issues can arise, and when they do, you need prompt and effective assistance to keep your projects running smoothly. Here's why our support stands out: ",
      image: featureTab02,
      lists: [
        {
          item: "Responsive Assistance",
        },
        {
          item: "Expertise and Knowledge",
        },
        {
          item: "Personalized Solutions",
        },
        {
          item: "Continuous Improvement",
        },
        {
          item: "Continuous Improvement",
        },
      ],
    },
    {
      title: "Security",
      content:
        "At SolTriks, we understand the critical importance of security in today's digital landscape. With cyber threats evolving rapidly, safeguarding your business and customer data is non-negotiable. Here's how we ensure your peace of mind:. ",
      image: featureTab03,
      lists: [
        {
          item: "Comprehensive Security Measures",
        },
        {
          item: "Proactive Threat Detection",
        },
        {
          item: "Continuous Improvement and Adaptation",
        },
        {
          item: "Robust Data Encryption",
        },
      ],
    },
  ],
};
import soltriks from "@/images/home_1/soltrikslogo.png";

export const ParallaxOneData = {
  iconName: soltriks,
  title: "Unlocking Success with ",
  specialText: "SOLTRIKS",
  text:
    "Success isn't just a destination; it's a journey we embark on with every client, every project, and every innovation. Our commitment to excellence and our relentless pursuit of perfection have propelled us to the forefront of the industry, earning us recognition and trust from clients around the globe.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "work showcase",
    subTitle: "our portfolio",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
};

import video02 from "@/images/home_1/3.jpg";
import aboutTwo01 from "@/images/home_1/2.jpg";
import aboutTwo02 from "@/images/home_1/1.jpg";

export const AboutTwoData = {
  sectionContent: {
    title: "",
    subTitle: "",
    text:
      "At SOLTRIKS, we are not just another company; we are masters of our expertise, committed to employing innovative strategies to deliver unparalleled solutions. As a leading provider of development and cybersecurity services, we take pride in our unwavering commitment to excellence.",
    text2:
      "With SOLTRIKS, you can be assured that you are partnering with the best in the industry. From state-of- the-art development to robust cybersecurity solutions, we have you comprehensively covered. Join us on the journey towards mastering digital solutions, where every challenge is addressed with innovation and every project is executed with precision.",
  },
  button: {
    label: "Learn More",
    url: "/about",
  },
  gallery: [
    // aboutTwo01,
    aboutTwo02,
  ],
};

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTwoData = {
  sectionContent: {
    title: "real experience",
    subTitle: "our core features",
    text:
      " We are committed to providing our customers with exceptional service while offering our employees the best training. ",
  },
  posts: [
    {
      title: "No Coding Skills Require",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "Online Documentation",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "SEO Optimized",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
  ],
  image: {
    text: "Total design freedom \n for everyone.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Reqeust a free quote",
    url: "/contact",
  },
];
